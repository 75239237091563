import { useEffect, useState } from "react";

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Sample app imports
import { Loading } from "../ui-components/Loading";
import { ErrorComponent } from "../ui-components/ErrorComponent";
import { callDoorOpenerApi } from "../utils/HttpTriggerApiCall";


const DoorOpenerContent = () => {  
    const { instance, inProgress } = useMsal();
    const [textResult, setText] = useState<null|string>(null);

    useEffect(() => {
      if (!textResult && inProgress === InteractionStatus.None) {

        callDoorOpenerApi().then(response => {
          setText(response);

          // redirect to Home screen after 5 secs
          setTimeout(() => {
            window.location.href = "/";
          }, 3000);

        }).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount() as AccountInfo
                    });
                }
            });
        }
    }, [inProgress, textResult, instance]);
  
    return (
        <>
        {textResult ? <center>{textResult} Redirecting in 3 secs ..</center> : "opening .." }
        </>
    );
};

export function DoorOpener() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
            errorComponent={ErrorComponent} 
            loadingComponent={Loading}
        >
         <DoorOpenerContent />
        </MsalAuthenticationTemplate>
      )
};