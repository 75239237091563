import { loginRequest, doorOpenerConfig } from "../authConfig";
import { msalInstance } from "../index";

export async function callDoorOpenerApi(): Promise<string> {

  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  const headers = new Headers();
  const bearer = `Bearer ${response.idToken }`;

  headers.append("Authorization", bearer);

  const requestBody = {
    name: account.username
  };

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(requestBody)
  };

  return fetch(doorOpenerConfig.endpoint, options)
    .then(response => response.text())  
    .catch(error => {
      console.log(error);
      return "error";
    });
}