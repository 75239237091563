import { Configuration, PopupRequest, BrowserCacheLocation } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "df336ddd-7470-4cbc-9ee4-494a7e6bdd0e",
        authority: "https://login.microsoftonline.com/0bf64d2c-f322-4c0a-9f47-10b53e8f0de0",
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage // "sessionStorage"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};


export const doorOpenerConfig = {
  endpoint: "https://hikimiki.azurewebsites.net/api/HttpTrigger1?code=lzFfMJ1CR0jOkngkxpLKDetvdpKxkRagTRRvBd7xo_fpAzFuZmsf5w=="
};

