import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

export function Home() {
  const { accounts } = useMsal();

  return (
    <>
      <AuthenticatedTemplate>
        <Button component={RouterLink} to="/door" variant="contained" color="secondary" size="medium" className="MainSecondaryButton" >Open the door!</Button>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Typography variant="h6" align="center">Please press Login before opening the door.</Typography>
      </UnauthenticatedTemplate>
    </>
  );
}
